:root {
	--background: linear-gradient(110deg, #e20074 4%, #653e9e 40%, #0070c0 68%, #33ccff 102%);
	--buttoncolor: linear-gradient(265.23deg, #cc0099 -1.5%, #e20074 105%);
	--buttoncoloractive: linear-gradient(265.23deg, #e20074 -1.5%, #33ccff 105%);
	--columns: 1fr 2fr 2fr 2fr 1fr;
	--areas: "footer1 footer2 footer3 footer4 footer5";
	--textcolor: white;
	--homebuttoncolor: white;
	color: var(--textcolor);
	--logo-filter: invert(0%);
	--slider-checked: #e20074;
}

[data-theme="orange"] {
	--background: linear-gradient(110deg, #ffc000 4%, #ff7600 40%, #f24405 68%, #ff0000 102%);
	--buttoncolor: linear-gradient(265.23deg, #ffb900 -1.5%, #ffa900 105%);
	--buttoncoloractive: linear-gradient(265.23deg, #ffc000 -1.5%, #ff0000 105%);
	--columns: 1fr 2fr 2fr 2fr 1fr;
	--areas: "footer1 footer2 footer3 footer4 footer5";
	--textcolor: white;
	--homebuttoncolor: white;
	color: var(--textcolor);
	--logo-filter: invert(0%);
	--slider-checked: #ffc000;
}

[data-theme="green"] {
	--background: linear-gradient(105.53deg, #00b050 4%, #77d932 40%, #c4f21b 68%, #ffff00 102%);
	--buttoncolor: linear-gradient(265.23deg, #00dd00 -1.5%, #00ff00 105%);
	--buttoncoloractive: linear-gradient(265.23deg, #ffff00 -1.5%, #00b050 105%);
	--columns: 1fr 4fr 4fr 4fr 1fr;
	--areas: "footer1 footer2 footer3 footer4 footer5";
	--textcolor: black;
	--homebuttoncolor: black;
	color: var(--textcolor);
	--logo-filter: invert(100%);
	--slider-checked: #00b050;
}

[data-theme="default"] {
	--background: linear-gradient(110deg, #e20074 4%, #653e9e 40%, #0070c0 68%, #33ccff 102%);
	--buttoncolor: linear-gradient(265.23deg, #cc0099 -1.5%, #e20074 105%);
	--buttoncoloractive: linear-gradient(265.23deg, #e20074 -1.5%, #33ccff 105%);

	--columns: 1fr 3fr 3fr 3fr 1fr;
	--areas: "footer1 footer2 footer3 footer4 footer5";
	--textcolor: white;
	--homebuttoncolor: white;
	color: var(--textcolor);
	--logo-filter: invert(0%);
}

/* --------------------------------------------------------------  General HTML tags  -------------------------------------------------------------------------------------- */

body {
	font-family: "TeleNeoOffice";
	text-align: center;
	font-size: 50px;
}

a:link,
a:visited {
	text-decoration: none;
	color: var(--background);
}

button {
	color: var(--textcolor);
}

/* twalzer: weiß nicht ob das sein muss */
#root {
	overflow: hidden;
	height: 100vh;
}

@font-face {
	font-family: TeleNeoOffice;
	src: url(./fonts/Tele\ Neo\ Office\ Thin.ttf);
}

/* --------------------------------------------------------------  Browser specific  -------------------------------------------------------------------------------------- */

::-webkit-scrollbar {
	display: none;
}

/* --------------------------------------------------------------  Grid defintion  -------------------------------------------------------------------------------------- */

#overlay {
	top: 10%;
	left: 5%;
	width: 90%;
	height: 80%;
	background-color: rgba(0, 0, 0, 0.65);
	display: none;
	position: absolute;
	border-radius: 1em;
	box-shadow: 8px 8px 4px rgba(240, 215, 215, 0.75);
	/*z-index: 100;*/
}

#streamOverlay {
	top: 30%;
	left: 30%;
	width: 40%;
	height: 20%;
	background-color: rgba(171, 158, 158, 0.9);
	display: none;
	position: absolute;
	border-radius: 1em;
	box-shadow: 8px 8px 4px rgba(240, 215, 215, 0.75);
	/*z-index: 100;*/
	/* text-align: center; */
	padding-top: 200px;
}

#html-spinner {
	width: 100px;
	height: 100px;
	border: 4px solid var(--slider-checked);
	border-top: 4px solid white;
	border-radius: 50%;
}

#html-spinner,
#svg-spinner {
	-webkit-transition-property: -webkit-transform;
	-webkit-transition-duration: 1.2s;
	-webkit-animation-name: rotate;
	-webkit-animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;

	-moz-transition-property: -moz-transform;
	-moz-animation-name: rotate;
	-moz-animation-duration: 1.2s;
	-moz-animation-iteration-count: infinite;
	-moz-animation-timing-function: linear;

	transition-property: transform;
	animation-name: rotate;
	animation-duration: 1.2s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@-webkit-keyframes rotate {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes rotate {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(360deg);
	}
}

@keyframes rotate {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

#html-spinner,
#svg-spinner {
	position: relative;
	top: 10px;
	/* margin-left: -24px; */
}

#html-spinner {
	left: 42%;
}
#overlayHeader {
	display: grid;
	grid-template-columns: 6fr 1fr;
}

#exitButton {
	background: var(--buttoncolor);
	height: 100px;
	width: 200px;
	box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	font-size: larger;
	/*position: absolute;*/
	margin: 20px;
}

.button-large {
	visibility: hidden;
}

.grid-container {
	display: grid;
	grid-template-areas:
		"header "
		"main"
		"footer";
	background: var(--background);
	grid-template-rows: 11fr 81fr 8fr;
	height: 100vh;
	transition: all 0.5s;
}

/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------  Header  -------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

#header {
	display: grid;
	grid-area: header;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-areas: "widgets  date headermenu";
	min-height: 90px;
	position: relative;
}

#date {
	grid-area: date;
}

#widgets {
	margin-left: 75px;
	overflow: visible;
	display: grid;
	height: 100%;
	grid-template-columns: 0.6fr 1fr 1fr 1fr;
	vertical-align: bottom;
}

.connection {
	height: 80px;
}

.batteryController {
	margin-left: 40px;
	height: 80px;
	overflow: visible;
}

.batteryCar {
	margin-left: 20px;
	height: 80px;
	overflow: visible;
}

.weather {
	margin-left: 10px;
	height: 95px;
	overflow: visible;
}

#headermenu {
	display: grid;
	grid-area: headermenu;
	height: 100%;
	width: 100%;
	grid-template-columns: 2.5fr 1fr;
}

.header_buttons {
	display: flex;
	align-items: center;
	justify-content: center;
	clip-path: polygon(0% 0%, 100% 0%, 92% 100%, 9% 100%);
	height: 72.72%;
	background: var(--buttoncolor);
	cursor: pointer;
	border: 0;
}

#button_profile {
	cursor: pointer;
	border: 0;
	background: none;
}

#button_settings {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	background: var(--buttoncolor);
	height: 72.72%;
	cursor: pointer;
	border: 0;
	clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0% 100%);
}

#profile_text {
	text-align: left;
	font-size: 50px;
	margin-left: 20px;
	font-weight: bold;
	text-align: center;
}

/* --------------------------------------------------------------  Widgets  -------------------------------------------------------------------------------------- */

.widget {
	padding-top: 15px;
	position: relative;
	overflow: visible;
	padding-right: 20px;
}
.widget_img {
	margin-left: -51px;
	height: 69px;
	position: absolute;
	overflow: visible;
}

#widget_weather {
	padding-top: 0px;
}

#widget_profileImage {
	display: flex;
	border-radius: 100px;
	height: 50%;
}

#widget_settingsGear {
	height: 60%;
	margin-left: 20px;
	filter: var(--logo-filter);
	transition: all 0.5s;
}

/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------  Footer  -------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

#footer {
	grid-area: footer;
	display: grid;
}

.footer-without-recommendations {
	grid-template-columns: 10% 2fr 2fr 10%;
}

.footer-with-recommendations {
	grid-template-columns: 10% 2fr 2fr 2fr 10%;
}

[data-theme="default-recommendations"] {
	--columns: 10% 2fr 2fr 10%;
	--areas: "footer1 footer2 footer3 footer4 footer5";
}

[data-theme="default-no-recommendations"] {
	--columns: 10% 2fr 2fr 10%;
	--areas: "footer1 footer2 footer3 footer4";
}

.footerItem {
	padding-top: 0px;
}

.footer_buttons {
	height: 100%;
	background: var(--buttoncolor);
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

#button_home {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.button-home-with-recommendations {
	clip-path: polygon(0% 0%, 100% 0%, 86% 100%, 0 100%);
}

.button-home-without-recommendations {
	clip-path: polygon(0% 0%, 100% 0%, 80% 100%, 0 100%);
}

#button_library {
	/*position: absolute;
	width: 820px;
	left: 185px;*/
	clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
}

#button_recommendation {
	/*position: absolute;
	width: 820px;
	left: -550px;*/
	clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0 100%);
}

#button_store {
	clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0 100%);
}

#button_community {
	clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0 100%);
}

#button_logout {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.button-logout-with-recommendations {
	clip-path: polygon(13% 0%, 100% 0%, 100% 100%, 0 100%);
}

.button-logout-without-recommendations {
	clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0 100%);
}

/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* --------------------------------------------------------------  Library  -------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

.subgrid-container {
	grid-area: main;
	display: grid;

	height: fit-content;
	grid-column-gap: 30px;
	grid-template-columns: 32.8% 13% 13% 13% 13%;
	/*border: 1px solid white;*/
	/*padding-top: 5em;*/
	grid-auto-flow: column;
	grid-auto-columns: minmax(13%, 1fr);
	overflow-x: scroll;
	scrollbar-width: none;
	overflow-y: scroll;
	padding-top: 15%;
}

.recommendations-overlay {
	grid-area: main;
	display: grid;

	height: fit-content;
	grid-column-gap: 30px;
	/*grid-template-columns: 32.8% 13% 13% 13% 13%;
	/*border: 1px solid white;*/
	/*padding-top: 5em;*/
	grid-auto-flow: column;
	grid-auto-columns: minmax(13%, 1fr);
	display: flex;
	justify-content: center;
	align-items: center;
}

.subgrid-container-details {
	grid-area: main;
	display: grid;
	grid-template-areas: "main";
}

.library-container {
	grid-area: main;
	overflow-y: scroll;
	scrollbar-width: none;
	overflow: scroll;
}

.library-container-filter {
	height: 80px;
	display: grid;
	gap: 50px;
	grid-template-columns: 3fr 1.5fr;
	margin-top: 25px;
	margin-left: 3%;
	margin-right: 3%;
	position: sticky;
	top: 10%;
}

@media (min-height: 1000px) {
	.library-container-filter {
		top: 7%;
	}
}

@media (min-height: 1209px) {
	.library-container-filter {
		top: 5%;
	}
}

@media (min-height: 2500px) {
	.library-container-filter {
		top: 2%;
	}
}

@media (min-height: 2500px) {
	.library-container-filter {
		top: 1%;
	}
}

#library-container-filter-field {
	width: 100%;
}

#library-container-filter-field::placeholder {
	color: var(--textcolor);
}

.library-container-apps {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	scrollbar-width: none;
	overflow-x: scroll;
	padding-top: 50px;
	margin-top: 30px;
	grid-row-gap: 100px;
}

.library-container-category-apps {
	display: grid;
	scrollbar-width: none;
	overflow-y: scroll;
	text-align: start;
	margin-left: 50px;
	margin-right: 50px;
	grid-auto-columns: min-content;
}

.library-category-strip {
	/*background-color: rgba(255, 255, 255, 0.2);*/
}

.library-category-strip-horizontal {
	display: grid;
	height: fit-content;
	grid-column-gap: 50px;
	grid-auto-flow: column;
	grid-auto-columns: minmax(13%, 1fr);
}

.library-checkbox-filter {
	width: 100%;
	overflow: hidden;
}

.library-checkbox-text {
	margin-bottom: 40px;
}

#checkbox-filter {
	background: rgba(217, 217, 217, 0.4);
	border: 1px solid rgba(0, 0, 0, 0.3);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: var(--slider-checked);
}

input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}

#category {
	padding-top: 24px;
	font-family: TeleNeoOffice;
	font-size: 48px;
	color: rgba(0, 0, 0, 0.65);
}

.libraryCategoryItem {
	display: grid;
	grid-template-rows: (6, 1fr);
}

.appdetails-container {
	display: grid;
	grid-template-rows: 5% 95%;
	grid-template-areas:
		"header"
		"main";
}

.appdetails-container-content {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	/*grid-template-rows: repeat(4, 1fr);*/
	height: 100%;
}

.appdetails-container-content-left {
	display: grid;
	/*grid-template-columns: repeat(2, 1fr);*/
	grid-template-rows: repeat(2, 1fr);
	grid-template-rows: 70% 30%;
	/*height: 75%;*/
}

.appdetails_titel {
	margin-top: -10px;
	margin-bottom: 10px;
}
.appdetails-container-content-right {
	display: grid;
	/*grid-template-columns: repeat(2, 1fr);*/
	grid-template-rows: 1fr, 2fr, 1fr; /*repeat(4, 1fr);*/
	/*padding: 100px;*/
	height: 100%;

	margin-right: 50px;
}

.appdetails-description {
	text-align: left;
}

.recommendations-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	scrollbar-width: none;
	overflow-x: scroll;
	padding-top: 50px;
	margin-top: 75px;
	grid-row-gap: 100px;
}

.recommendations-empty {
	margin-left: 800px;
}

.subgrid-container-all {
	grid-area: main;
	display: grid;
	/* grid-column-gap: 60px;*/

	grid-template-columns: repeat(4, 1fr);
	scrollbar-width: none;
	overflow-x: scroll;
	padding-top: 60px;
}

.profile-container-content {
	display: grid;
	grid-template-columns: 40% 60%;
	height: 100%;
}

.profile-container-content-left {
	display: grid;
	padding-top: 60px;
}

.profile-container-content-right {
	display: grid;
	grid-template-rows: 50% 50%;
	padding-top: 100px;
	text-align: left;
}

.profile-information {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	gap: 10px;
}

.profile-achievements-pics {
	padding-right: 10px;
	display: grid;
	grid-template-columns: repeat(20, 1fr);
	gap: 20px;
	scrollbar-width: none;
	overflow-y: scroll;
}

.levelbar {
	height: 50px;
	width: 500px;
	background-color: #d8d8d8;
	border-radius: 20px;
	border: 2px solid white;
	box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
}

.levelbar-done {
	height: 100%;
	width: 500px;
	border-radius: 20px;
	background: linear-gradient(to right, blue, #63b8ff);
	display: flex;
	justify-content: right;
}

.levelbar-label {
	padding: 5;
	color: white;
	font-weight: bold;
}

.profile-buttons-achievements {
	padding: 0;
	cursor: pointer;
	background: no-repeat;
	border: none;
}

.profile-buttons-friends {
	padding: 0;
	cursor: pointer;
	background: no-repeat;
	border: none;
}

.popup,
.popup-overlay {
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	position: fixed;
}

.popup-overlay {
	 {
		/*background: rgba(49,49,49,0.8);*/
	}
}

.popup-content {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	line-height: 1.4;
	background-color: rgba(0, 0, 0, 0.65);
	padding: 14px 28px;
	border-radius: 3px;
	max-width: 600px;
	min-width: 500px;
	color: var(--textcolor);
}

.popup-close {
	position: absolute;
	top: 10px;
	right: 10px;
	padding: 5px 7px;
	background: var(--buttoncolor);
	box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
	margin: 20px;
}

.community-container-content {
	display: grid;
	grid-area: main;

	grid-template-columns: 50% 50%;
	grid-gap: 1vw;
	height: 100%;
	padding-left: 20px;
	padding-right: 40px;
}

.community-container-content-left {
	display: grid;
	grid-template-rows: 70% 26%;
	grid-gap: 1vh;
	padding-top: 1vh;
}

.community-container-content-right {
	display: grid;
	grid-template-rows: 70% 28%;
	grid-gap: 1vh;
	padding-top: 1vh;
}

.community-button-mehranzeigen {
	background: var(--buttoncolor);
	box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
	border-radius: 5px;
}

.community-areas-sofdcar {
	/*display: grid;*/
	border: 0.35rem outset white;
	box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	overflow-y: scroll;
	font-size: 35px;
	scrollbar-width: none;
	padding-left: 10px;
	padding-right: 10px;
}

.community-areas-updatenews {
	border: 0.35rem outset white;
	box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	font-size: 35px;
	overflow-y: scroll;
}

.community-areas-dates {
	border: 0.35rem outset white;
	box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	overflow-y: scroll;

	font-size: 35px;
}

.community-areas-qr {
	border: 0.35rem outset white;
	box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	height: 102%;
}

#community-qrcode {
	width: 20%;
	aspect-ratio: 1/1;
}

#more {
	display: none;
}
/* .settings-container-content{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
 
  margin-left: 450px;
  margin-right: 450px;
  text-align: left;
  height: 90%; 
  margin-top: 50px;
} */

.libaryImage {
	box-shadow: 5px 10px 8px #888888;
	/*width: 75%;*/
	height: 300px;
}

.appdetails-button-back {
	width: 150px;
	height: 102px;
	margin-top: 50px;
	background: var(--buttoncolor);
	box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	font-size: 30px;
}

.appdetails-button-stream {
	width: 361px;
	height: 102px;
	margin-top: 50px;
	background: var(--buttoncolor);
	box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	font-size: 30px;
}

.appdetails-logo {
	padding-top: 100px;
}

.appdetails-tags {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 10px;
}
.appdetails-container-content-right-header {
	display: grid;
	grid-template-columns: 3fr 1fr;
}
.appdetails-tag {
	background: var(--buttoncolor);
	border-radius: 20px;
	height: 102px;
	padding: 0 15px 0 15px;
	box-shadow: 8px 8px 4px rgba(0, 0, 0, 0.25);
	display: flex;
	justify-content: center;
	align-items: center;
}
.game-name {
	margin-top: 0px;
	margin-bottom: 20px;
	font-size: 30px;
}

/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* --------------------------------------------------------------  Buttons  -------------------------------------------------------------------------------------- */

/* Button Class */
.clock {
	padding-top: 10px;
	font-size: 85%;
}

.button_text {
	font-family: TeleNeoOffice;
	font-weight: 100;
}

.button_texts {
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.button_active {
	outline: 5px solid white;
	/*outline-offset: -2px;*/
}

#button_home_icon {
	height: 60%;
	margin-right: 20px;
	filter: var(--logo-filter);
	transition: all 0.5s;
}

#button_logout_icon {
	height: 60%;
	margin-left: 20px;
	filter: var(--logo-filter);
	transition: all 0.5s;
}

/* ---------- SETTINGS ---------- */

/* Settings - Grid Definition */

.settings-grid {
	display: grid;
	grid-template-rows: repeat(6, 1fr);
	margin-left: 200px;
	margin-right: 200px;
	height: 75%;
	margin-top: 5vh;
}

.settings-recommendations-grid {
	display: grid;
	grid-template-columns: 2fr 2fr;
	grid-template-areas: "recommendationsxtext . recommendationsbutton";
	text-align: left;
	align-items: center;
	border-bottom: 1px solid white;
	margin-bottom: 20px;
}

.settings-language-grid {
	display: grid;

	grid-template-columns: 2fr 2fr 0.5fr 0.5fr;
	grid-template-areas: "languagetext . languagede languageen";
	text-align: left;
	align-items: center;
	border-bottom: 1px solid white;
	margin-bottom: 20px;
}

.settings-appstyle-grid {
	display: grid;

	grid-template-columns: 2fr 2fr 0.6fr 0.6fr 0.6fr;
	grid-template-areas: "appstyletext . appstyle1 appstyle2 appstyle3";
	text-align: left;
	align-items: center;
	border-bottom: 1px solid white;
	margin-bottom: 20px;
}

.settings-graphics-grid {
	display: grid;

	grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 2fr;
	grid-template-areas: "graphicstextfront graphicsbutton1 graphicsbutton2 graphicsbutton3 graphicsbutton4 graphicsbutton5 graphicstextend";
	text-align: center;
	align-content: center;
	align-items: center;
	border-bottom: 1px solid white;
	margin-bottom: 20px;
}

/* Settings - Headline */

.settings-headline {
	text-align: center;
}

/* ---------- */

/* Settings - Show recommendations */

.settings-recommendations-subgrid {
	grid-area: recommendationsbutton;
	text-align: right;
	align-items: center;
}

/* body .wrap {
  width: 250px;
  height: 100px;
  position: absolute;
  left: calc(83% - 125px);
  top: calc(39% - 50px);
}

body .wrap:first-of-type {
  margin-top: -50px;
}

body .wrap:first-of-type .inner {
  transition: 0.4s ease-in-out;
  transition-delay: 0s;
}

body .wrap:first-of-type .outer {
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  transition-delay: 0.4s;
}

body .wrap:first-of-type .outer:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 80px;
  background: linear-gradient(to right, #CC0099 -1.5%, #E20074 105%);
}

body .wrap:first-of-type input:checked ~ .outer {
  -webkit-animation: shift 0.8s ease-in-out 1 forwards;
          animation: shift 0.8s ease-in-out 1 forwards;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

body .wrap:first-of-type input:checked ~ .outer:before {
  background: linear-gradient(to left, #CC0099 -1.5%, #E20074 105%);
}

@-webkit-keyframes shift {
  0% {
    width: 250px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    width: 100px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    width: 100px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    width: 250px;
    -webkit-transform: translateX(150px) rotate(180deg);
            transform: translateX(150px) rotate(180deg);
  }
}

@keyframes shift {
  0% {
    width: 250px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    width: 100px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    width: 100px;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    width: 250px;
    -webkit-transform: translateX(150px) rotate(180deg);
            transform: translateX(150px) rotate(180deg);
  }
}

body .wrap:first-of-type input:checked ~ .outer ~ .inner {
  transition-delay: 0.4s;
  -webkit-transform: translateX(150px);
          transform: translateX(150px);
  box-shadow: inset 5px -10px 15px 0 rgba(0, 0, 0, 0.5);
}

body .wrap:nth-of-type(2) {
  margin-top: 75px;
}

body .wrap:nth-of-type(2) input:checked {
  z-index: 7;
}

body .wrap:nth-of-type(2) input:checked ~ .outer {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  margin-left: 150px;
}

body .wrap:nth-of-type(2) input:checked ~ .inner {
  margin-left: 150px;
  box-shadow: inset 5px -10px 15px 0 rgba(0, 0, 0, 0.5);
}

body .outer, body input {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  border-radius: 80px;
  opacity: 0;
}

body .outer:nth-of-type(2), body input:nth-of-type(2) {
  z-index: 8;
}

body .outer {
  background: linear-gradient(to right, #CC0099 -1.5%, #E20074 105%);
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.25), 0 0 20px 0 rgba(0, 0, 0, 0.15);
  opacity: 1;
  z-index: 0;
  -webkit-transform-origin: 50px 50px;
          transform-origin: 50px 50px;
  transition: margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition-delay: 0s, 0.3s;
}

body .inner {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #fff;
  box-shadow: inset -5px -10px 15px 0 rgba(0, 0, 0, 0.5);
  background-position: -35px -30px;
  background-size: 120%;
  position: absolute;
  left: calc(50% - 115px);
  top: calc(50% - 40px);
  border-radius: 500%;
  transition: margin 0.3s ease-in-out, box-shadow 0.6s ease-in-out;
  transition-delay: 0.3s, 0s;
} */

/* ---------- */

/* Setting - Language */

.settings-language-subgrid-de {
	grid-area: languagede;
	text-align: right;
	vertical-align: middle;
	align-items: center;
}

.settings-language-subgrid-en {
	grid-area: languageen;
	text-align: right;
	vertical-align: middle;
	align-items: center;
}

.settings-button-language-de {
	width: 120px;
	height: 80px;
	vertical-align: middle;
	background: url("../src/images/flaggen/ger.png") no-repeat;
	background-size: 100%;
	border: none;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7);
	cursor: pointer;
}

.settings-language-subgrid-en {
	grid-area: languageen;
	text-align: right;
	vertical-align: middle;
	align-items: center;
}

.settings-button-language-en {
	width: 120px;
	height: 80px;
	vertical-align: middle;
	background: url("../src/images/flaggen/eng.png") no-repeat;
	background-size: 100%;
	border: none;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7);
	cursor: pointer;
}

/* ---------- */

/* Settings - App Style */

.settings-appstyle-subgrid-1 {
	grid-area: appstyle1;
	text-align: right;
	vertical-align: middle;
	align-items: center;
}

.settings-appstyle-subgrid-2 {
	grid-area: appstyle2;
	text-align: right;
	vertical-align: middle;
	align-items: center;
}

.settings-appstyle-subgrid-3 {
	grid-area: appstyle3;
	text-align: right;
	vertical-align: middle;
	align-items: center;
}

.settings-button-appstyle-1 {
	width: 130px;
	height: 60px;
	left: 1176px;
	top: 446px;
	background: linear-gradient(105.53deg, #ffc000 22.41%, #ff0000 87.19%);
	border-radius: 10px;
	vertical-align: middle;
	border: none;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7);
	cursor: pointer;
}

.settings-button-appstyle-2 {
	width: 130px;
	height: 60px;
	left: 1273px;
	top: 446px;
	background: linear-gradient(105.53deg, #00b050 22.41%, #ffff00 87.19%);
	border-radius: 10px;
	vertical-align: middle;
	border: none;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7);
	cursor: pointer;
}

.settings-button-appstyle-3 {
	width: 130px;
	height: 60px;
	left: 1370px;
	top: 446px;
	background: linear-gradient(105.53deg, #e20074 22.41%, #33ccff 87.19%);
	border-radius: 10px;
	vertical-align: middle;
	border: none;
	box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.7);
	cursor: pointer;
}

/* ---------- */

/* Settings - Graphics */

.settings-graphics-text-front {
	text-align: left;
}

.settings-graphics-button-1 {
	grid-area: graphicsbutton1;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid #ffffff;
	vertical-align: middle;
	margin: auto;
	color: black;
	cursor: pointer;
}

.settings-graphics-button-2 {
	grid-area: graphicsbutton2;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid #ffffff;
	vertical-align: middle;
	margin: auto;
	color: black;
	cursor: pointer;
}

.settings-graphics-button-3 {
	grid-area: graphicsbutton3;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid #ffffff;
	vertical-align: middle;
	margin: auto;
	color: black;
	cursor: pointer;
}

.settings-graphics-button-4 {
	grid-area: graphicsbutton4;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid #ffffff;
	vertical-align: middle;
	margin: auto;
	color: black;
	cursor: pointer;
}

.settings-graphics-button-5 {
	grid-area: graphicsbutton5;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid #ffffff;
	vertical-align: middle;
	margin: auto;
	color: black;
	cursor: pointer;
}

.settings-graphics-text-end {
	text-align: right;
}

/* __________________________________________________ */

/* ---------- LOGIN PAGE ---------- */

.login {
	margin-top: 100px;
	/*color: white;*/
	display: grid;
	grid-template-columns: 6fr 2fr;
	font-size: 70px;
	font-weight: 80;
	font-family: "TeleNeoOffice";
}

#login_username {
	margin-top: 70px;
	margin-bottom: 50px;
	font-size: 48px;
}

#login_password {
	margin-bottom: 70px;
	font-size: 48px;
}

input {
	background: rgba(217, 217, 217, 0.2);
	border: 1px solid rgba(0, 0, 0, 0.3);
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
	border-radius: 20px;
	font-family: TeleNeoOffice;
	font-size: 48px;
	color: var(--textcolor);
	padding: 0px 0px 0px 20px;
}

input:focus {
	border: 1px solid rgba(0, 0, 0, 0.3);
	outline: none;
}

.ProfileButton {
	border: none;
	outline: none;
	background: none;
}

.switchProfileImage {
	height: 200px;
	border-radius: 70px;
}

.ProfileImage {
	box-shadow: 20px 20px 4px rgba(0, 0, 0, 0.15);
	border-radius: 100px;
	width: 400px;
}
.glow-on-hover {
	width: 300px;
	height: 70px;
	border: none;
	outline: none;
	color: rgba(226, 0, 116);
	font-size: 36px;
	background: white;
	cursor: pointer;
	position: relative;
	z-index: 0;
	border-radius: 10px;
}

.glow-on-hover:before {
	content: "";
	background: linear-gradient(
		45deg,
		#ff0000,
		#ff7300,
		#fffb00,
		#48ff00,
		#00ffd5,
		#002bff,
		#7a00ff,
		#ff00c8,
		#ff0000
	);
	position: absolute;
	top: -2px;
	left: -2px;
	background-size: 400%;
	z-index: -1;
	filter: blur(5px);
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	animation: glowing 20s linear infinite;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	border-radius: 10px;
}

.glow-on-hover:active {
	color: white;
}

.glow-on-hover:active:after {
	background: transparent;
}

.glow-on-hover:hover:before {
	opacity: 1;
}

.glow-on-hover:after {
	z-index: -1;
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background: white;
	left: 0;
	top: 0;
	border-radius: 10px;
}

@keyframes glowing {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active > .footer_buttons {
	background: var(--buttoncoloractive);
	border: 2px solid var(--textcolor);
}

.active > .header_buttons {
	background: var(--buttoncoloractive);
	border: 2px solid var(--textcolor);
}

.active > #button_settings {
	background: var(--buttoncoloractive);
	border: 2px solid var(--textcolor);
}

.active > .button_recommendation {
	background: var(--buttoncoloractive);
	border: 2px solid var(--textcolor);
}
